import React, { useState, useMemo } from "react";
import Link from "next/link";
import Carousel from "react-multi-carousel";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { PromoTermDialog } from "components/terms/";

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const bgPromo = `${serverUrlImages}/promo/Group.svg`;
const bgFamily = `${serverUrlImages}/promo/family-promo-covid.png`;
const proteccioncovid360 = `${serverUrlImages}/promo/proteccioncovid360.svg`;

const icon1 = `${serverUrlImages}/promo/monitoring-icon.svg`;
const icon3 = `${serverUrlImages}/promo/coverage-icon.svg`;

const logoAsistensi = `${serverUrlImages}/promo/Logo-asis-1.png`;
const pills = `${serverUrlImages}/promo/icono%2B1.png`;
const logoFarmatodo = `${serverUrlImages}/promo/Logo-farma-1.png`;
const cupon = `${serverUrlImages}/promo/Cupon-1.png`;
const termsFarmatodo = `${serverUrlImages}/documents/VEN-PromoInclusionPLANES22.02.2022.pdf`;

const responsive = {
  all: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    partialVisibilityGutter: 50,
  },
};

const Promo = ({ goToHomeQuotation, slide, setSlide }) => {
  const [showDialog, setShowDialog] = useState(false);

  // available 31/01/22
  const promoExpired = useMemo(() => {
    const currentDate = new Date();
    const expiredDate = new Date("2022-03-31T23:59:59");

    return currentDate > expiredDate;
  }, []);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (!reason) {
      setShowDialog(false);
    }
  };

  return (
    <>
      <style>
        {`
          .bg-gradient {
            background: linear-gradient(89.89deg, #200844 5.64%, rgba(32, 8, 68, 0) 60.34%);
          }
          @media only screen and (max-width: 959px) {
            .hide-bg {
              background: none !important;
            }
          }
          .dot-members {
            margin-top: 40px !important;
          }
          .dot-members .react-multi-carousel-dot--active button {
            background: #fb314a !important;
          }
          .dot-members .react-multi-carousel-dot button {
            border: 0 !important;
            background: #e6ebf1;
            width: 8px !important;
            height: 8px !important;
            margin-right: 12px !important;
          }
          .min-size-arrow {
            min-width: 30px;
            min-height: 30px;
          }
          .react-multiple-carousel__arrow {
            z-index: 10 !important;
          }
          @media only screen and (max-width: 959px) {
            .bgDesktop {
              background-color: #E1E4F4 !important;
            }
          }
          @media only screen and (min-width: 1280px) and (max-width: 1500px) {
            .img-desktop {
              margin-right: 8%!important;
              max-width: 38rem !important;
            }
            .img-desktop-22 {
              max-width: 55rem !important;
              margin-left: 2rem !important;
            }
          }
          @media only screen and (min-width: 1121px) and (max-width: 1280px) {
            .img-desktop-22 {
              max-width: 50rem !important;
            }
          }
          @media only screen and (min-width: 960px) and (max-width: 1120px) {
            .img-desktop {
              margin-right: 3% !important;
              max-width: 34rem !important;
            }
            .img-desktop-22 {
              margin-left: -6rem !important;
            }
          }

          @media only screen and (min-width: 300px) and (max-width: 600px) {
            .aster {
              margin-top: 1rem !important;
            }
          }
          @media only screen and (min-width: 600px) and (max-width: 960px) {
            .aster {
              margin-top: 1.5rem !important;
            }
          }
          @media only screen and (min-width: 1280px) {
            .farmaBanner {
              background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(187,192,206,1) 100%);
            }
          }    
          @media only screen and (max-width: 1279px) {
            .farmaBanner {
              background: radial-gradient(circle, rgba(255,255,255,1) 73%, rgba(187,192,206,1) 100%);
            }
          }
          .hrPromo {
            line-height: 1em;
            outline: 0;
            border: 1;
            border-color:#200944 !important;
            text-align: center;
            height: 1.5em;
          }
        }
        `}
      </style>
      <div className="relative pb-12">
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          arrows={true}
          showDots={true}
          dotListClass="dot-members"
          renderDotsOutside={true}
          customRightArrow={
            <KeyboardArrowRightIcon className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right min-size-arrow text-white" />
          }
          customLeftArrow={
            <KeyboardArrowLeftIcon className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left min-size-arrow text-white" />
          }
          beforeChange={(nextSlide, { currentSlide, onMove }) => {
            setSlide(nextSlide);
          }}
        >
          {/* FIRST */}
          {/* TODO: paused promo farmatodo */}
          {/* {!promoExpired && (
            <div className="h-full w-full bg-cover bg-no-repeat flex flex-col lg:flex-row py-16 farmaBanner">
              <div className="w-full lg:w-3/4 xl:w-2/3 flex flex-col justify-center items-center">
                <h2 className="w-full text-purple-500 font-bold text-3xl md:text-5xl lg:flex text-center">
                  <span className=" lg:w-1/2 font-poppins text-purple-500 lg:text-right lg:pr-24">
                    <span className=" lg:w-1/2 text-pink-500 font-poppins">
                      Juntos{" "}
                    </span>
                    para{" "}
                  </span>
                  <span className="lg:inline-block font-poppins lg:text-left lg:pl-24">
                    cuidarte mejor
                  </span>
                </h2>
                <div className="w-full h-full lg:h-auto flex items-center justify-center lg:-mt-24 px-12">
                  <img src={logoAsistensi} className="w-1/3 xl:w-auto" />
                  <img src={pills} className="w-1/3 xl:w-auto" />
                  <img src={logoFarmatodo} className="w-1/3 xl:w-auto" />
                </div>
                <div className="w-full flex flex-col lg:flex-row justify-center items-center text-center lg:text-left lg:-mt-8 px-12 lg:px-2 lg:px-0 mt-2 lg:mt-0">
                  <p className="w-full md:w-3/5 lg:w-1/2 font-poppins text-purple-500 leading-snug text-base md:text-xl lg:text-2xl lg:pl-24">
                    Este mes asegura a los tuyos en Venezuela hoy mismo y obtén
                    un{" "}
                    <span className="uppercase font-bold font-poppins text-purple-500">
                      cupón* de:
                    </span>
                  </p>
                  <span className="relative">
                    <img src={cupon} className="py-1 w-full" />
                    <span className="absolute top-0 right-0 text-pink-500 font-poppins font-bold text-3xl sm:text-4xl lg:text-5xl sm:mr-4 md:mt-6 lg:mt-0 aster">
                      *
                    </span>
                  </span>
                </div>
                <div className="w-full flex justify-center">
                  <p className="hidden lg:flex bg-pink-600 font-poppins text-white text-2xl rounded rounded-r-full rounded-l-full px-12">
                    para compras en Farmatodo con envío a domicilio incluido
                  </p>
                </div>
              </div>
              <div className="w-full h-full lg:w-1/4 xl:w-1/3 flex flex-col md:justify-end items-center">
                <div className="hidden lg:block">
                  <button
                    className="primary-button my-2 lg:my-0 mr-4 md:mr-6"
                    onClick={() => goToHomeQuotation()}
                  >
                    <span className="sm:hidden">¡Cotizar!</span>
                    <span className="sm:inline hidden">¡Quiero cotizar!</span>
                  </button>
                  <a
                    href={termsFarmatodo}
                    target="_blank" rel="noopener noreferrer"
                  >
                    <button className="white-btn my-8 text-purple-500 hover:text-white">
                      <span className="">Ver términos y condiciones</span>
                    </button>
                  </a>
                </div>
                <p className="flex flex-col lg:hidden bg-purple-500 font-poppins text-white text-center text-base px-4 md:px-6 py-1 rounded rounded-r-full rounded-l-full md:mt-0">
                  <span className="font-poppins text-sm sm:text-xl">
                    para compras en Farmatodo con{" "}
                  </span>
                  <span className="text-pink-500 font-poppins text-sm sm:text-xl">
                    envío a domicilio incluido{" "}
                  </span>
                </p>
                <hr className="lg:hidden w-10/12 mt-8 hrPromo" />
                <p className="w-10/12 lg:w-full font-poppins text-purple-500 text-center lg:text-left text-xs md:text-sm mr-4 lg:py-16 px-4 lg:px-4">
                  *Cupón válido sólo para ser canjeado en el módulo Escanea y
                  Listo o a través de la página web para envíos a domicilio
                </p>
                <a
                  href={termsFarmatodo}
                  target="_blank" rel="noopener noreferrer"
                  className="lg:hidden font-poppins text-center lg:text-left text-xs underline cursor-pointer mr-4 lg:py-16 px-4 lg:px-0 mt-4">
                    Ver términos y condiciones
                </a>
              </div>
            </div>
          )} */}

          {/* SECOND */}
          <div
            className="bg-right bg-contain bg-no-repeat bg-purple-500 relative h-full flex items-center"
            style={{ backgroundImage: `url('${bgPromo}')` }}
          >
            <div className="w-full flex flex-col md:flex-row">
              <div
                className="bg-right bg-cover bg-no-repeat rounded-2xl m-2 md:w-3/4 lg:w-2/3 hide-bg"
                style={{ backgroundImage: `url('${bgFamily}')` }}
              >
                <div className="pt-12 px-5 h-full md:py-12 md:px-8 lg:px-14 lg:py-16 xl:px-20 xl:py-32 bg-gradient">
                  <div className="md:max-w-smaller w-full flex flex-col text-white leading-snug text-3xl md:text-2xl lg:text-3xl">
                    <p className="text-pink-500 font-poppins">
                      En estos momentos de pandemia,
                    </p>
                    <h2 className="font-poppins font-bold">
                      bríndales a tus seres queridos el nuevo beneficio de
                      asistensi
                    </h2>
                  </div>
                  <div className="flex md:flex-col mt-6 lg:flex-row">
                    <button
                      className="primary-button my-2 lg:my-0 mr-4 md:mr-6"
                      onClick={() => goToHomeQuotation()}
                    >
                      <span className="sm:hidden">¡Cotizar!</span>
                      <span className="sm:inline hidden">¡Quiero cotizar!</span>
                    </button>
                    <Link
                      href={{
                        pathname: "/affiliation",
                        query: { origin: "affiliate" },
                      }}
                      as="/comenzar-afiliacion"
                    >
                      <button className="white-btn my-2 lg:my-0 text-purple-500 hover:text-white">
                        <span className="sm:hidden">¡Contratar!</span>
                        <span className="sm:inline hidden">
                          ¡Contratar ahora!
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex items-center flex-col py-12 px-5 md:px-8 xl:px-20 lg:py-16 xl:py-32 md:w-1/4 lg:w-2/6">
                <img
                  loading="lazy"
                  src={proteccioncovid360}
                  alt="proteccion covid360"
                  className="h-20 md:h-24 w-auto"
                />
                <div className="flex flex-col mt-8 md:mt-0 lg:mt-12">
                  <div className="flex items-center my-2">
                    <span className="bg-white rounded-full p-4 mr-4 flex items-center justify-center relative">
                      <img
                        loading="lazy"
                        alt="icono"
                        src={icon1}
                        className="absolute"
                      />
                    </span>
                    <p className="font-poppins leading-snug text-xl lg:text-2xl xl:text-3xl text-pink-500">
                      Monitoreo constante
                    </p>
                  </div>
                  {/* <div className="flex items-center my-2">
                    <span className="bg-white rounded-full p-4 mr-4 flex items-center justify-center relative">
                      <img
                        loading="lazy"
                        alt="icono"
                        src={icon2}
                        className="absolute"
                      />
                    </span>
                    <p className="font-poppins leading-snug text-xl lg:text-2xl xl:text-3xl text-pink-500">
                      Hospitalización en casa
                    </p>
                  </div> */}
                  <div className="flex items-center my-2">
                    <span className="bg-white rounded-full p-4 mr-4 flex items-center justify-center relative">
                      <img
                        loading="lazy"
                        alt="icono"
                        src={icon3}
                        className="absolute"
                      />
                    </span>
                    <p className="font-poppins leading-snug text-xl lg:text-2xl xl:text-3xl text-pink-500">
                      Cobertura en las mejores clínicas
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* THIRD */}
          {/* <div className="bgDesktop relative flex items-center h-full min-h-800p sm:min-h-560px md:min-h-0">
            <img loading="lazy" src={bgPromo3} className="hidden md:block md:absolute w-full top-0 right-0 h-full md:min-h-0"/>
            <div className="flex flex-col w-full mx-auto px-8 md:px-32 relative z-10">
              <div className="py-5 w-full md:max-w-md lg:max-w-2xl xl:max-w-4xl flex flex-col leading-snug text-3xl md:text-2xl lg:text-3xl font-poppins">
                <p className="text-purple-500 font-bold text-3xl font-poppins lg:text-4xl leading-none">Estas Navidades:</p>
                <h2 className="text-pink-500 font-poppins font-bold text-4xl lg:text-5xl xl:text-6xl leading-none uppercase tracking-wide mt-0 md:w-screen">Reencuentro Familiar</h2>
                <p className="mt-4 text-base lg:text-xl font-bold text-purple-500 leading-relaxed md:max-w-70">
                  Participa en nuestro sorteo y gana dos boletos aéreos para que tus familiares viajen desde Venezuela a reencontrarse contigo, sin importar las distancias. 
                </p>
                <div className="flex items-center mt-4 w-full text-2xl md:max-w-70">
                  <span><img loading="lazy" src={iconPlane} alt="icono"/></span><p className="ml-2 uppercase text-pink-500 font-bold">¿Cómo participar? </p>
                </div>
                <p className="my-5 text-purple-500 text-lg text-base leading-relaxed md:max-w-70">
                  Muy sencillo, afilia a un ser querido en Venezuela con <span className="text-pink-500 font-poppins font-bold">asistensi</span>, y ¡listo! <span className="font-bold">automáticamente estás participando.</span>
                </p>
                <div>
                  <button className="mt-7 md:mt-4 mb-0 primary-button min-w-full md:min-w-0 my-2 lg:my-0 mr-4 md:mr-6" onClick={() => goToHomeQuotation()}>
                    <span>¡Cotiza un plan ya!</span>
                  </button>
                </div>
              </div>
              <img loading="lazy" name="promo-reunificacion-familiar-venezuela" alt="Promoción para reunificación familiar en Venezuela" src={family} className="md:hidden right-0 md:-top-20 w-full h-auto md:w-3/5 min-h-300p md:h-full sm:max-w-xl mx-auto"/>
            </div>
            <img loading="lazy" name="promo-reunificacion-familiar-venezuela" alt="Promoción para reunificación familiar en Venezuela" src={familyDesktop} className="hidden md:inline absolute bottom-0 md:max-w-xl lg:max-w-2xl xl:max-w-3xl h-auto right-0 img-desktop" style={{ marginRight: '12%' }}/>
          </div> */}
        </Carousel>
      </div>

      <PromoTermDialog
        open={showDialog}
        handleClose={handleClose}
        isSignUp={true}
        goToHomeQuotation={goToHomeQuotation}
      />
    </>
  );
};

export default Promo;
